<template>
  <div class="container">
    <div class="navsteps navstepts-flight">
      <div class="navsteps__item active">
          <div class="number">1</div>
          <div class="descr">Разрахунок</div>
      </div>
      <div class="navsteps__item">
          <div class="number">2</div>
          <div class="descr">Вибір СК</div>
      </div>
      <div class="navsteps__item">
          <div class="number">3</div>
          <div class="descr">Оформлення</div>
      </div>
    </div>
    <FlightSearchForm />
</div>
</template>

<script>
import FlightSearchForm from '@/views/flight/components/FlightSearchForm.vue'
  export default {
    components: {FlightSearchForm},
  }
</script>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
