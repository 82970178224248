<template>
    <div class="mainsearch flight-search">
        <form @submit.prevent="submitHandler">
            <div class="form">
                <div class="row">
                    <div class="col-12">
                        <div class="mainsearch__heading">Інформація по рейсу</div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Звідки</label>
                            <div class="city-container-item">
                                <citySearch
                                :citySelect='citySelect'
                                v-bind:cityStore="this.cityStoreFrom"
                            />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Куди</label>
                            <div class="city-container-item">
                                <citySearch
                                :citySelect='citySelectTo'
                                v-bind:cityStore="this.cityStoreTo"
                            />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Дата та час від’їзду</label>
                            <date-picker v-model="startDate" :config="optionsStartDate" :class="['form-control', 'date-input', {invalid: (!$v.startDate.required && submitCheck)}]" placeholder="Дати від’їзду"></date-picker>
                            <small class="error" v-if="!$v.startDate.required && submitCheck">Вкажіть дати від’їзду</small>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Дата та час прибуття</label>
                            <date-picker v-model="endDate" :config="optionsEndDate" :class="['form-control', 'date-input', {invalid: (!$v.endDate.required && submitCheck) || dateValidate}]" placeholder="Дати прибуття"></date-picker>
                            <small class="error" v-if="!$v.endDate.required && submitCheck">Вкажіть дати прибуття</small>
                            <small class="error" v-if="dateValidate">Дата прибуття не може бути меньша за дату від’їзду</small>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="form-group">
                            <label>Номер квитка</label>
                            <input v-model="ticketNumber" type="text" :class="['form-control', {invalid: (!$v.ticketNumber.required && submitCheck)}]" placeholder="Введіть номер квитка">
                            <small class="error" v-if="!$v.ticketNumber.required && submitCheck">Вкажіть номер квитка</small>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group ticket-price">
                            <label>Вартість квитка</label>
                            <div>
                                <input v-model="ticketPrice" type="number" :class="['form-control', {invalid: (!$v.ticketPrice.required && submitCheck)}]" placeholder="Вартість квитка">
                                <small class="error" v-if="!$v.ticketPrice.required && submitCheck">Вкажіть вартість квитка</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 insurance-nav d-flex justify-content-center">
                        <button class="btn-blue-w" type="submit">
                            Показати результати
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import citySearch from '@/components/app/forms/CitySearch.vue'
    import {maxLength, minLength, numeric, required, requiredIf} from 'vuelidate/lib/validators';
    import { findCityById } from '@/api/dictionary';

    export default {
        components: {Multiselect, citySearch},
        props: ['dataStartDate',
                'dataEndDate',
                'dataCityFrom',
                'dataCityTo',
                'dataTicketNumber',
                'dataTicketPrice'],
        data: function () {
            return {
                //TODO этот момент переписать, когда поменяем форму управления
                citiesOptions: [ {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone":1},
                  {"id": 9,"name": "Дніпро","name_full_name_ua": "м. Дніпро, Дніпропетровська  обл., Україна", "zone":2},
                  {"id": 11,"name": "Львів","name_full_name_ua": "м. Львів, Львівська  обл., Україна", "zone":2},
                  {"id": 13,"name": "Одеса","name_full_name_ua": "м. Одеса, Одеська  обл., Україна", "zone":2},
                  {"id": 14,"name": "Харків","name_full_name_ua": "м. Харків, Харківська  обл., Україна", "zone":2},
                  {"id": 16,"name": "Запоріжжя","name_full_name_ua": "м. Запоріжжя, Запорізька  обл., Україна", "zone":3},
                  {"id": 17,"name": "Кривий Ріг","name_full_name_ua": "м. Кривий Ріг, Дніпропетровська  обл., Україна", "zone":3},
                  {"id": 21,"name": "Вінниця","name_full_name_ua": "м. Вінниця, Вінницька  обл., Україна", "zone":4},
                  {"id": 36,"name": "Маріуполь","name_full_name_ua": "м. Маріуполь, Донецька  обл., Україна", "zone":4},
                  {"id": 41,"name": "Полтава","name_full_name_ua": "м. Полтава, Полтавська  обл., Україна", "zone":4}],
                city: null,
                cityTo: null,
                startDate: null,
                endDate: null,
                ticketNumber: null,
                ticketPrice: null,
                submitCheck: false,
                optionsStartDate: {
                    format: 'DD.MM.YYYY HH:mm',
                    useCurrent: false,
                    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                    locale: 'ru',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                        time: "fa fa-clock-o",
                        date: "fa fa-calendar",
                    },
                },
                optionsEndDate: {
                    format: 'DD.MM.YYYY HH:mm',
                    useCurrent: false,
                    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                    locale: 'ru',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                        time: "fa fa-clock-o",
                        date: "fa fa-calendar",
                    },
                },
                errors: {},
            };
        },

        validations: {
            startDate:{ required},
            endDate:{ required},
            ticketNumber:{required},
            ticketPrice:{required, numeric}
        },

        created() {
            this.fetchParams();
        },

        mounted: function () {
        },

        computed: {
            cityStoreFrom() {
                if(this.dataCityFrom){
                    return this.dataCityFrom
                }
            },
            cityStoreTo() {
                if(this.dataCityTo){
                    return this.dataCityTo
                }
            },
            dateValidate(){
                let startYear = parseInt(moment(this.startDate,'DD/MM/YYYY').format('YYYY'));
                let startMonth = parseInt(moment(this.startDate,'DD/MM/YYYY').format('MM'));
                let startDay = parseInt(moment(this.startDate,'DD/MM/YYYY').format('DD'));
                let startHour = parseInt(moment(this.startDate,'DD/MM/YYYY/HH:m').format('HH'));
                let startMinutes = parseInt(moment(this.startDate,'DD/MM/YYYY/HH:m').format('mm'));
                let endYear = parseInt(moment(this.endDate,'DD/MM/YYYY').format('YYYY'));
                let endMonth = parseInt(moment(this.endDate,'DD/MM/YYYY').format('MM'));
                let endDay = parseInt(moment(this.endDate,'DD/MM/YYYY').format('DD'));
                let endHour = parseInt(moment(this.endDate,'DD/MM/YYYY/HH:m').format('HH'));
                let endMinutes = parseInt(moment(this.endDate,'DD/MM/YYYY/HH:m').format('mm'));
                let diffDays = moment(new Date((endYear+1), new Date(endMonth-1), new Date(endDay-1), new Date(endHour-1), new Date(endMinutes-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1), new Date(startHour-1), new Date(startMinutes-1)), 'hours', true);

                return diffDays < 0
            }
        },

        watch: {
        },

        methods: {
            submitHandler() {
                this.submitCheck = true;
                if (this.$v.$invalid) {
                    this.$v.$touch()

                    return
                }

                if(this.dateValidate){
                    return false
                }

                let params = {
                    from: this.city,
                    to: this.cityTo,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    ticketNumber: this.ticketNumber,
                    ticketPrice: this.ticketPrice
                }

                this.$store.commit('SET_BUS_SEARCH_PARAMS', params);
                if (this.checkResultRoute()) {
                    this.$router.go();
                } else {
                    this.$router.push({name: `busResults`});
                }
            },
            otherCity() {
                let name = 'Другие населенные пункты Украины';

                let results = this.cities.filter((city) => {
                    return name.includes(this.cityPartner.name_full_name_ua);
                });

                if(results.length === 0) {
                    return {
                        name_full_name_ua: name,
                    };
                }

                return results[0];
            },

            otherCountry: function () {
                let name = 'ТС зареєстровано в іншій країні';

                let results = this.otherVariants.filter((city) => {
                    return name.includes(city.name_ru);
                });

                if(results.length === 0) {
                    return {
                        name: null,
                    };
                }

                return results[0];
            },

            fetchCities(query) {
                axios.get('/api/directories/cities/find?city=' + query).then(({data}) => {
                    this.cities = data.data;
                    this.citiesOptions = data.data;

                    if(this.citiesOptions.length === 0) {
                        this.citiesOptions.push(this.otherCity);
                    }
                }).catch(err => (console.log(err)));
            },
            searchCities(query) {
                if(query.length > 1){
                    this.fetchCities(query);
                }
            },

            citySelect(data) {
                this.city = data.city;
            },

            citySelectTo(data) {
                this.cityTo = data.city;
            },

            isInvalid(val) {
                return val == null && this.submitForm;
            },

            fetchParams(){
                this.startDate = this?.dataStartDate;
                this.endDate = this?.dataEndDate;
                this.ticketNumber = this?.dataTicketNumber;
                this.ticketPrice = this?.dataTicketPrice;
            },

            checkResultRoute() {
                return this.$router.currentRoute.name === 'busResults';
            },
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


